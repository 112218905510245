export default function useConfig() {
  const coreProducts = [
    {
      title: 'OCR病历识别',
      desc: '专门正对医疗领域开发和训练的OCR模型，对医疗常见的字词，缩写，标识以及单位等情况有效识别了超过90%。',
      brief: '专门正对医疗领域开发和训练的OCR模型',
      id: 12
      // icon: '',
      // bgImg: ''
    },
    {
      title: '病历分类',
      desc: '能区分病案首页，门急诊病历、入院住院出院小结、化验单检验报告、B超CT等病历报告，发票清单等20多种类型。',
      brief: '区分病案首页，门急诊病历、发票清单等20多种类型。',
      id: 15
      // icon: '',
      // bgImg: ''
    },
    {
      title: '检验报告内容提取',
      desc: '提取表头信息，如患者信息，辅助信息、医院信息等等；提取化验信息，如检测项，检测值，参考值，单位等。',
      brief: '提取表头信息，如患者信息，辅助信息、医院信息等等',
      id: 14
      // icon: '',
      // bgImg: ''
    },
    {
      title: '费用清单内容提取',
      desc: '提取清单汇总信息、医院信息、患者信息，提取医疗明细信息，如药品名称、数量、价格、甲乙类等。',
      brief: '提取清单汇总信息、医院信息、患者信息',
      id: 11
      // icon: '',
      // bgImg: ''
    },
    {
      title: '发票识别',
      desc: '识别发票中相关细项内容，如开票日期、单位、付款人、发票项目和金额。',
      brief: '智能识别发票中相关细项内容',
      id: 10
      // icon: '',
      // bgImg: ''
    },
    {
      title: '体检报告内容提取',
      desc: '对体检报告做详细的解析，按照体检报告的格式和内容，给出相关结构化信息。',
      brief: '对体检报告做详细的解析，给出相关结构化信息。',
      id: 8
      // icon: '',
      // bgImg: ''
    },
    {
      title: '核保内容提取',
      desc: '对住院入院出院以及各项检查报告做结构化处理，将异常、疾病、症状、病史、治疗等等7大类信息，详细结构化。',
      brief: '对住院入院出院以及各项检查报告做结构化处理',
      id: 6
      // icon: '',
      // bgImg: ''
    },
    {
      title: '理赔内容提取',
      desc: '对化验单和病历结构化做归一化，对费用清单、医疗三目做归一化',
      brief: '对化验单和病历结构化做归一化',
      id: 5
      // icon: '',
      // bgImg: ''
    }
  ]

  const solveWays = [
    {
      title: '理赔RPA'
      // icon: ''
    },
    {
      title: '医疗单位归一化'
      // icon: ''
    },
    {
      title: '理赔风控'
      // icon: ''
    },
    {
      title: '核保RPA'
      // icon: ''
    },
    {
      title: '疾病名称归一化'
      // icon: ''
    },
    {
      title: '既往史筛查'
      // icon: ''
    }
  ]

  return {
    coreProducts,
    solveWays
  }
}
